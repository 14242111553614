<template>
  <div class="semaltVue">Semalt vue.js App</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
<style scoped>
.semaltVue {
  font-size: 14px;
  font-weight: bold;
}
</style>
